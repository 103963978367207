<template>
  <div class="stack">
    <div
      class="stack__child"
      v-for="(stack, index) in element.children"
      :key="index"
    >
      <div v-if="stack.type === 'stack'">
        <Stack :element="stack" :style="getStyle(stack)" />
      </div>
      <div
        :style="getStyle(stack)"
        :class="stack.classes.textStyles"
        v-html="stack.text"
        v-else
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Stack",
  props: {
    element: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam"
    }),
    brand() {
      return this.team.brand;
    },
    textStyles() {
      return this.brand?.textStyles;
    }
  },
  methods: {
    getStyle(element) {
      let styles = {
        fontSize: this.textStyles[element.classes.textStyles]?.fontSize
          ? this.textStyles[element.classes.textStyles].fontSize + "px"
          : undefined,
        lineHeight: this.textStyles[element.classes.textStyles]?.lineHeight
          ? this.textStyles[element.classes.textStyles].lineHeight + "px"
          : undefined,
        textTransform: this.textStyles[element.classes.textStyles]
          ?.textTransform
          ? this.textStyles[element.classes.textStyles].textTransform
          : undefined,
        letterSpacing: this.textStyles[element.classes.textStyles]
          ?.letterSpacing
          ? this.textStyles[element.classes.textStyles].letterSpacing
          : undefined
      };

      return {
        ...element.css[0],
        ...element.css[this.orientation],
        fontSize: element.css[this.orientation].fontSize
          ? element.css[this.orientation].fontSize
          : styles.fontSize,
        lineHeight: element.css[this.orientation].lineHeight
          ? element.css[this.orientation].lineHeight
          : styles.lineHeight,
        textTransform: element.css[this.orientation].textTransform
          ? element.css[this.orientation].textTransform
          : styles.textTransform,
        letterSpacing: element.css[this.orientation].letterSpacing
          ? element.css[this.orientation].letterSpacing
          : styles.letterSpacing
      };
    }
  }
};
</script>

<style lang="scss">
.stack {
  &__child {
    margin: unset !important;
  }
}
</style>
