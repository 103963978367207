<template>
  <div
    class="editor-element-bar"
    v-if="template && template.elements && template.elements.length > 0"
  >
    <div
      class="editor-element-bar__element"
      v-for="(element, index) in templateElements"
      :key="index"
    >
      <div
        class="editor-element-bar__element-label"
        v-if="element.type === 'text' && element.overrides != 0"
      >
        {{ element.name }}
      </div>
      <textarea-autosize
        class="editor-element-bar__text"
        v-model="element.text"
        v-if="element.type === 'text' && element.overrides != 0"
      >
      </textarea-autosize>

      <StackTextArea :element="element" v-if="element.type === 'stack'" />

      <div
        class="editor-element-bar__picture"
        v-if="element.image && element.overrides != 0"
      >
        <div class="editor-element-bar__picture-main-options">
          <div class="editor-element-bar__element-label">
            {{ element.name }}
          </div>
          <div class="editor-element-bar__element-load-btns">
            <label
              :for="
                `editor-element-bar__editor-element-bar-bpicture-upload-modal-tn-${index}`
              "
              class="editor-element-bar__upload-btn"
            >
              {{ $t("pages.editor.uploadPhoto") }}
            </label>
            <input
              :id="
                `editor-element-bar__editor-element-bar-bpicture-upload-modal-tn-${index}`
              "
              type="file"
              ref="imageFile"
              @change="loadImage($event, index)"
              accept=".png,.jpg,.jpeg"
            />
            <!-- <Button
              class="editor-element-bar__element-generateImage-btn"
              icon="sparkle"
              @click="openGenerateImageModal(element)"
              theme="secondary"
            /> -->
          </div>
        </div>
        <div class="editor-element-bar__feedback">
          {{ imageFeedback }}
        </div>
        <div
          class="editor-element-bar__element-options editor-element-bar__element-options__image"
        >
          <Button
            class="editor-element-bar__element-image-settings-btn"
            @click="toggleImageSettings(index)"
            theme="expandCollapse"
            icon="Down"
          >
            {{ $t("pages.editor.imageSettings") }}
          </Button>
          <div
            v-if="element.showSettings"
            class="editor-element-bar__element-options__image-settings"
          >
            <div
              class="editor-element-bar__element-options__image-settings__image-fitting"
            >
              <Button
                class="editor-element-bar__element-option"
                :theme="
                  element.classes.imageStyles === 'contain'
                    ? 'primary'
                    : 'background'
                "
                @click="element.classes.imageStyles = 'contain'"
              >
                {{ $t("pages.editor.contain") }}
              </Button>
              <Button
                class="editor-element-bar__element-option"
                :theme="
                  element.classes.imageStyles === 'cover'
                    ? 'primary'
                    : 'background'
                "
                @click="element.classes.imageStyles = 'cover'"
              >
                {{ $t("pages.editor.cover") }}
              </Button>
              <Button
                class="editor-element-bar__element-crop-btn"
                icon="Crop"
                @click="cropImage(index)"
                theme="secondary"
              />
            </div>
            <!-- <Button
              class="editor-element-bar__element-bg-btn"
              icon="Magic-Wand"
              theme="secondary"
              @click="openCreditModal(element)"
              v-if="canShowBGRemoveBtn(element)"
              >{{ $t("pages.editor.removeBG") }}
            </Button> -->
          </div>
        </div>
      </div>

      <div
        class="editor-element-bar__video"
        v-if="element.video && element.overrides != 0"
      >
        <div class="editor-element-bar__video-main-options">
          <div class="editor-element-bar__element-label">
            {{ element.name }}
          </div>
          <label
            :for="`editor-element-bar__video-upload-btn-${index}`"
            class="editor-element-bar__upload-btn"
          >
            {{ $t("pages.editor.uploadVideo") }}
          </label>
          <input
            :id="`editor-element-bar__video-upload-btn-${index}`"
            type="file"
            ref="file"
            @change="loadVideo($event, index)"
            accept=".mov,.mp4"
          />
        </div>
        <div class="editor-element-bar__feedback">
          {{ videoFeedback }}
        </div>

        <div class="editor-element-bar__element-options">
          <Button
            class="editor-element-bar__element-option"
            :theme="
              element.classes.imageStyles === 'contain'
                ? 'primary'
                : 'background'
            "
            @click="element.classes.imageStyles = 'contain'"
          >
            {{ $t("pages.editor.contain") }}
          </Button>
          <Button
            class="editor-element-bar__element-option"
            :theme="
              element.classes.imageStyles === 'cover' ? 'primary' : 'background'
            "
            @click="element.classes.imageStyles = 'cover'"
          >
            {{ $t("pages.editor.cover") }}
          </Button>
        </div>
      </div>
    </div>
    <!-- <div class="editor-element-bar__generateContent">
      <Button
        class="editor-element-bar__generateContent-btn"
        icon="sparkle"
        @click="openGenerateContentModal(element)"
      >
        <span class="editor-element-bar__generateContent-label">{{
          $t("pages.editor.generateContent.heading")
        }}</span>
      </Button>
    </div> -->
    <!-- New Modal for Generate Image Feature -->
    <Modal
      v-if="showGenerateImageModal"
      class="editor-element-bar__generateImage-modal"
      @close="showGenerateImageModal = false"
      :heading="$t('pages.editor.generateImage.heading')"
    >
      <template #modal-content>
        <div
          class="editor-element-bar__generateImage-modal__body"
          v-if="credits > 0"
        >
          <input
            type="text"
            v-model="generateImagePrompt"
            :placeholder="$t('pages.editor.generateImage.placeholder')"
            class="editor__input"
          />
          <Button
            class="button"
            @click="submitGenerateImagePrompt"
            :disabled="isSubmitting"
          >
            <span v-if="isSubmitting">
              <i class="fa fa-spinner fa-spin"></i> Loading...
            </span>
            <span v-else>
              <inline-svg
                class="dashboard__create-slide-btn__icon"
                :src="require(`../../assets/icons/Magic-Wand.svg`)"
                aria-label="Create"
              ></inline-svg>
            </span>
          </Button>
        </div>
        <span class="editor-element-bar__generateImage-modal__credits">
          {{ credits }} {{ $t("pages.editor.creditModal.infoSubTitle") }}
        </span>
      </template>
    </Modal>

    <!-- New Modal for Generate Content Feature -->
    <Modal
      v-if="showGenerateContentModal"
      class="editor-element-bar__generateContent-modal"
      @close="showGenerateContentModal = false"
      :heading="$t('pages.editor.generateContent.heading')"
    >
      <template #modal-content>
        <div
          class="editor-element-bar__generateContent-modal__body"
          v-if="credits > 0"
        >
          <input
            type="text"
            v-model="generateContentPrompt"
            :placeholder="$t('pages.editor.generateContent.placeholder')"
            class="editor__input"
          />
          <Button
            class="button"
            @click="submitGenerateContentPrompt"
            :disabled="isSubmitting"
          >
            <span v-if="isSubmitting">
              <i class="fa fa-spinner fa-spin"></i> Loading...
            </span>
            <span v-else>
              <inline-svg
                class="dashboard__create-slide-btn__icon"
                :src="require(`../../assets/icons/Magic-Wand.svg`)"
                aria-label="Create"
              ></inline-svg>
            </span>
          </Button>
        </div>
        <span class="editor-element-bar__generateContent-modal__credits">
          {{ credits }} {{ $t("pages.editor.creditModal.infoSubTitle") }}
        </span>
      </template>
    </Modal>

    <Modal
      v-if="showCreditModal"
      class="editor-element-bar__credit-modal"
      @close="showCreditModal = false"
      :heading="$t('pages.editor.creditModal.title')"
    >
      <template #modal-content>
        <div class="editor-element-bar__credit-modal-icon"></div>
        <div class="editor-element-bar__credit-modal-info-title">
          {{ $t("pages.editor.creditModal.infoTitle") }}
        </div>
        <div class="editor-element-bar__credit-modal-info-sub-title">
          {{ credits }} {{ $t("pages.editor.creditModal.infoSubTitle") }}
        </div>
        <Button
          class="editor-element-bar__credit-modal-confirm-btn"
          @click="onRemoveBackground"
          v-if="credits > 0"
        >
          {{ $t("pages.editor.creditModal.removeBtn") }}
        </Button>
        <Button
          class="editor-element-bar__credit-modal-confirm-btn"
          @click="goToSupport"
          v-else
          theme="secondary"
        >
          {{ $t("pages.editor.creditModal.contactBtn") }}
        </Button>
      </template>
    </Modal>

    <Modal
      class="editor-element-bar__picture-upload-modal"
      v-if="showPictureModal"
      @close="showPictureModal = false"
      :heading="$t('pages.editor.pictureModal.title')"
    >
      <div
        class="editor-element-bar__picture-upload-modal-content"
        slot="modal-content"
      >
        <div class="editor-element-bar__picture-upload-modal-field">
          <vue-croppie
            class="editor-element-bar__picture-upload-modal-cropper"
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :enforceBoundary="false"
            :boundary="boundary"
            :viewport="viewport"
          >
          </vue-croppie>
        </div>

        <Button
          class="editor-element-bar__picture-upload-modal-set-btn"
          @click="setImage"
        >
          {{ $t("pages.editor.crop") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import StackTextArea from "@/components/StackTextArea";
import "vue-advanced-cropper/dist/style.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button,
    Modal,
    StackTextArea
  },
  props: {
    template: {
      type: Object,
      default: () => {}
    },
    slideId: {
      type: String,
      default: ""
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    imageStyles: ["contain", "cover"],
    showCreditModal: false,
    showPictureModal: false,
    showGenerateImageModal: false,
    showGenerateContentModal: false,
    generateImagePrompt: "",
    generateContentPrompt: "",
    currentElement: null,
    imageFeedback: "",
    videoFeedback: "",
    elementData: {
      index: 0,
      width: "0px",
      height: "0px"
    },
    targetElement: null,
    isSubmitting: false
  }),
  computed: {
    ...mapGetters("teams", {
      credits: "getCredits",
      currentTeam: "getCurrentTeam"
    }),
    boundary() {
      return {
        width: this.elementData.width,
        height: this.elementData.height
      };
    },
    viewport() {
      return {
        width: this.elementData.width,
        height: this.elementData.height,
        type: "square"
      };
    },
    templateElements() {
      let elements = this.template.elements.map(element => {
        if (!element.showSettings) {
          Vue.set(element, "showSettings", false);
        }
        if (element.type === "text") {
          element.text = element.text.replace(/<div>/g, "\n");
          element.text = element.text.replace(/<br>/g, "\n");
          element.text = element.text.replace(/&nbsp;/g, " ");
          element.text = element.text.replace(/<\/div>/g, "");
          return element;
        }

        return element;
      });
      return elements;
    }
  },
  methods: {
    ...mapActions("teams", ["fetchTeamCredits"]),
    ...mapActions("slides", ["removeBackground"]),
    calculateWidthAutoDimensions(left, right) {
      return 1920 - left - right;
    },
    calculateHeighthAutoDimensions(top, bottom) {
      return 1080 - top - bottom;
    },
    cropImage(index) {
      this.showPictureModal = true;

      const maxWidth = 1000;
      const maxHeight = 600;

      const imageWidth =
        this.template.elements[index].css[this.orientation].width === "auto"
          ? this.calculateWidthAutoDimensions(
              this.template.elements[index].css[this.orientation].left.replace(
                "px",
                ""
              ),
              this.template.elements[index].css[this.orientation].right.replace(
                "px",
                ""
              )
            )
          : this.template.elements[index].css[this.orientation].width.replace(
              "px",
              ""
            );
      const imageHeight =
        this.template.elements[index].css[this.orientation].height === "auto"
          ? this.calculateHeighthAutoDimensions(
              this.template.elements[index].css[this.orientation].top.replace(
                "px",
                ""
              ),
              this.template.elements[index].css[
                this.orientation
              ].bottom.replace("px", "")
            )
          : this.template.elements[index].css[this.orientation].height.replace(
              "px",
              ""
            );

      const widthRatio = maxWidth / imageWidth;
      const heightRatio = maxHeight / imageHeight;
      const scale = Math.min(widthRatio, heightRatio);

      const croppieWidth =
        imageWidth > maxWidth || imageHeight > maxHeight
          ? imageWidth * scale
          : imageWidth;
      const croppieHeight =
        imageWidth > maxWidth || imageHeight > maxHeight
          ? imageHeight * scale
          : imageHeight;

      console.log(this.template.elements[index].css[this.orientation].width);

      this.elementData = {
        index: index,
        width: croppieWidth + "px",
        height: croppieHeight + "px"
      };

      this.$nextTick(() => {
        this.$refs.croppieRef.bind({
          url: this.template.elements[index].image.src
        });
      });
    },
    canShowBGRemoveBtn(element) {
      return (
        element.image.isChanged && element.image.src.startsWith("https://")
      );
    },
    openCreditModal(element) {
      this.currentElement = element;
      this.fetchTeamCredits().then(() => {
        this.showCreditModal = true;
      });
    },
    openGenerateContentModal() {
      //had 'element' as function variable but why do we need it?
      this.fetchTeamCredits().then(() => {
        //this.currentElement = element; // commented it out to prevent error
        this.showGenerateContentModal = true;
      });
    },
    openGenerateImageModal(element) {
      this.fetchTeamCredits().then(() => {
        this.currentElement = element;
        this.showGenerateImageModal = true;
      });
    },
    submitGenerateImagePrompt() {
      this.isSubmitting = true;
      const url =
        "https://europe-west3-lobbyspace2.cloudfunctions.net/generateImage";
      const data = {
        prompt: this.generateImagePrompt,
        teamId: this.currentTeam.id
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.blob())
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob);
          console.log("Success:", imageUrl);
          // Update the image source here
          this.currentElement.image.src = imageUrl;
          this.currentElement.image.isChanged = true;
          this.isSubmitting = false;
          this.showGenerateImageModal = false;
        })
        .catch(error => {
          console.error("Error:", error);
          this.isSubmitting = false;
        });
    },
    updateElementText(elements, jsonData) {
      elements.forEach(element => {
        if (jsonData[element.id]) {
          element.text = jsonData[element.id].text;
        }
        if (element.children && element.children.length > 0) {
          this.updateElementText(element.children, jsonData);
        }
      });
    },
    submitGenerateContentPrompt() {
      this.isSubmitting = true;
      const url =
        "https://europe-west3-lobbyspace2.cloudfunctions.net/generateSlideContent";
      const hasSingleImageWithOverride =
        this.template.elements.filter(
          element => element.type === "image" && element.overrides === "1"
        ).length === 1;
      const data = {
        url: this.generateContentPrompt,
        elements: this.template.elements,
        getImage: hasSingleImageWithOverride,
        teamId: this.currentTeam.id
      };
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          var jsonData = JSON.parse(data.content);
          var imageData = data.imageData;
          //console.log(data.content)
          console.log("Current template elements:", this.template.elements);
          this.updateElementText(this.template.elements, jsonData);

          console.log(imageData);
          // Apply the blob to the image element with overrides = 1
          if (imageData && hasSingleImageWithOverride) {
            const imageElement = this.template.elements.find(
              element => element.type === "image" && element.overrides === "1"
            );
            console.log(imageElement);
            if (imageElement) {
              imageElement.image.src = imageData;
              imageElement.image.isChanged = true;
            }
          }

          this.isSubmitting = false;
          this.showGenerateContentModal = false;
        })
        .catch(error => {
          console.error("Error:", error);
          this.isSubmitting = false;
        });
    },
    onRemoveBackground() {
      let payload = {
        slideId: this.slideId,
        elementId: this.currentElement.id,
        imageUrl: this.currentElement.image.src
      };

      this.removeBackground(payload);
      this.currentElement = null;
      this.showCreditModal = false;
    },
    goToSupport() {
      this.$router.push({
        name: "Settings",
        params: {
          id: "support"
        }
      });
    },
    loadImage(e, index) {
      const maxFileSize = 1024 * 1024 * 10; // 10MB
      const allowedExtensions = ["png", "jpg", "jpeg"];

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const extension = files[0].name.split(".").pop();
      const size = files[0].size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.imageFeedback = "";

        if (size < maxFileSize) {
          this.imageFeedback = "";
          let reader = new FileReader();

          reader.onload = e => {
            this.template.elements[index].image.src = e.target.result;
            this.template.elements[index].image.isChanged = true;
          };

          reader.readAsDataURL(files[0]);
          e.target.value = "";
          setTimeout(() => {
            this.cropImage(index);
          }, 500);
        } else {
          this.imageFeedback = this.$t("pages.editor.imageMaxFileSize");
        }
      } else {
        this.imageFeedback = this.$t("pages.editor.imageFileType");
      }
    },
    setImage() {
      let options = {
        type: "base64",
        size: {
          width: this.elementData.width.replace("px", ""),
          height: this.elementData.height.replace("px", "")
        }
      };
      this.$refs.croppieRef.result(options, output => {
        this.template.elements[this.elementData.index].image.src = output;
        this.template.elements[this.elementData.index].image.isChanged = true;
      });
      this.showPictureModal = false;
    },
    checkIfPlaceholder(element) {
      if (typeof element.video.src === "string") {
        return element.video.src.includes("img/video-placeholder");
      }
    },
    loadVideo(e, index) {
      const maxFileSize = 1024 * 1024 * 1024; // 1 GB
      const allowedExtensions = ["mov", "mp4"];
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const extension = files[0].name.split(".").pop();
      const size = files[0].size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.videoFeedback = "";

        if (size < maxFileSize) {
          this.videoFeedback = "";

          this.template.elements[index].video.src = files[0];
          this.template.elements[index].video.isChanged = true;
          this.template.elements[index].video.url = URL.createObjectURL(
            files[0]
          );
          this.setDuration();
        } else {
          this.videoFeedback = this.$t("pages.editor.videoMaxFileSize");
        }
      } else {
        this.videoFeedback = this.$t("pages.editor.videoFileType");
      }
    },
    async setDuration() {
      let videos = [];
      for (const element of this.template.elements) {
        if (element.type === "video" && !this.checkIfPlaceholder(element)) {
          if (element.video.url) {
            let x = await this.getVideoDuration(element.video.src, true);
            videos.push(x);
          } else {
            let x = await this.getVideoDuration(element.video.src, false);
            videos.push(x);
          }
        }
      }
      this.$emit("duration-changed", Math.max(...videos));
    },
    getVideoDuration(file, blob) {
      return new Promise((resolve, reject) => {
        try {
          let video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = function() {
            resolve(Math.floor(video.duration));
          };

          video.onerror = function() {
            reject("Invalid video. Please select a video file.");
          };
          if (blob) {
            video.src = window.URL.createObjectURL(file);
          } else {
            video.src = file;
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    toggleImageSettings(index) {
      this.templateElements[index].showSettings = !this.templateElements[index]
        .showSettings;
    }
  }
};
</script>

<style lang="scss">
.editor-element-bar {
  position: fixed;
  left: 0;
  top: 53px;
  padding: 16px 12px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 3;
  overflow-y: auto;

  &__generateContent {
    @apply border-ls-gray-300 p-3;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    border-top-style: solid;
    border-top-width: 1px;
  }
  &__generateContent-btn {
    background: linear-gradient(298deg, #c750ff 9.71%, #9f67ff 90.91%);
    border-style: none;
  }

  &__element-image-settings-btn {
    width: 100%;
  }
  &__element-options__image {
    @apply p-3 bg-white;
    border-radius: 0 !important;
    flex-direction: column;
  }
  &__element-options__image-settings {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    &__image-fitting {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
    }
  }
  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__text {
    @apply bg-ls-gray-100 w-full rounded-lg p-2 mb-4;
    resize: none;
  }

  &__picture,
  &__video {
    &-main-options {
      @apply p-3;
    }
    @apply border bg-ls-gray-100 rounded-lg border-ls-gray-200 mb-4;
  }

  &__feedback {
    @apply text-ls-red mt-2 text-xs font-semibold;
  }

  &__upload-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold w-full text-center;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  input[type="file"] {
    display: none;
  }

  &__element-load-btns {
    @apply flex;
  }

  &__image-style {
    @apply text-sm;
  }

  &__element-options-dropdown {
    @apply w-full;
  }

  &__element-options {
    @apply flex mt-2 rounded-lg;
  }

  &__element-option {
    @apply w-full;
  }

  &__element-generateImage-btn {
    svg * {
      fill: #af5fff;
    }
    .btn__icon {
      margin: 0;
    }
  }

  &__element-crop-btn {
    .btn__icon {
      margin: 0;
    }
  }

  &__element-bg-btn {
    margin-left: 0px;
    svg * {
      fill: #af5fff;
    }
  }

  &__element-crop-btn,
  &__element-generateImage-btn {
    @apply ml-2;
  }

  .cr-resizer {
    @apply border border-ls-gray-300;
  }

  .cr-boundary {
    @apply border border-ls-gray-300;
  }

  &__credit-modal {
    .modal__content-inner {
      @apply flex flex-col items-center;
    }
  }

  &__credit-modal-icon {
    background-image: url("../../assets/img/ai.png");
    background-size: contain;
    width: 48px;
    height: 48px;
    margin-top: 16px;
  }

  &__credit-modal-info-title {
    @apply mt-2 font-semibold text-sm;
  }

  &__credit-modal-info-sub-title {
    @apply text-sm;
  }

  &__credit-modal-confirm-btn {
    @apply w-full mt-4;
  }
  &__picture {
    overflow: hidden;
  }
  &__editor-element-bar__picture-upload-modal-cropper {
    overflow: auto;
  }
  &__generateImage-modal {
    .modal__content {
      box-shadow: 0px 0px 14px 0px rgba(168, 99, 255, 0.2),
        0px 4px 53px 0px rgba(199, 80, 255, 0.28);
    }
    .modal__content-inner {
      margin-top: 8px;
    }
    &__credits {
      @apply text-sm text-ls-gray-800;
    }
    &__body {
      display: flex;
      margin-bottom: 16px;
    }
    .button {
      margin-left: 8px;
      background: linear-gradient(298deg, #c750ff 9.71%, #9f67ff 90.91%);
      border: none !important;
      &__icon * {
        fill: white;
      }
    }
  }
}
</style>
