<template>
  <div class="editor-template-content">
    <canvas
      class="editor-template-content__canvas"
      ref="canvas"
      v-if="showCanvas"
    ></canvas>
    <EditorTemplateElementBar
      v-if="slide && template"
      :template="template"
      :slide-id="slide.id"
      @duration-changed="onDurationChanged"
      :orientation="orientation"
    />

    <div
      class="editor-template-content__preview"
      :class="{ paused: isPaused, 'orientation-2': orientation === '2' }"
      v-if="template && template.elements && template.elements.length > 0"
      :style="previewStyle"
      ref="preview"
    >
      <div
        class="editor-template-content__element"
        v-for="element in template.elements"
        :key="element.id"
      >
        <div
          class="image-container"
          v-if="element.type === 'image'"
          :style="getStyle(element)"
        >
          <div
            class="image-container__remove-bg"
            v-if="element.image.removeBgInProgress === true"
          >
            <img
              :src="require(`../../assets/icons/removebg-progress.gif`)"
              aria-label="progress"
            />
          </div>
          <div
            v-if="!element.image.src.includes('img/image-placeholder')"
            :style="getImageStyle(element)"
            class="image-container__image"
          />

          <!-- <img v-else :src="imagePlaceholder" /> -->
          <div
            v-else
            :style="getImagePlaceHolderStyle(element)"
            class="image-container__image"
          />
        </div>

        <div
          class="image-container"
          :class="element.classes.logoType"
          v-if="element.type === 'logo'"
          :style="getStyle(element)"
        >
          <div :style="getLogoStyle(element)" class="image-container__logo" />
        </div>

        <div
          class="video-container"
          v-if="element.type === 'video'"
          :style="getStyle(element)"
        >
          <video
            v-if="!checkIfPlaceholder(element) && !element.video.url"
            :src="element.video.src"
            :class="element.classes.imageStyles"
            muted
            crossorigin="anonymous"
          />

          <video
            :class="element.classes.imageStyles"
            v-else-if="element.video.url"
            :src="element.video.url"
            muted
            crossorigin="anonymous"
          />

          <video
            :class="element.classes.imageStyles"
            v-if="checkIfPlaceholder(element)"
            :poster="videoPlaceholder"
            :src="videoPlaceholder"
            muted
            crossorigin="anonymous"
          />
        </div>

        <div
          v-if="element.type === 'text'"
          :style="getTextStyle(element)"
          :class="element.classes.textStyles"
          class="editor-template-content__text-element"
        >
          <span v-html="element.text"></span>
        </div>

        <Stack
          v-if="element.type === 'stack'"
          :style="getTextStyle(element)"
          :class="element.classes.textStyles"
          class="editor-template-content__text-element"
          :element="element"
          :orientation="orientation"
        />

        <div
          v-if="element.type === 'shape'"
          :style="getStyle(element)"
          class="editor-template-content__shape-element"
        >
          <div class="editor-template-content__shape" v-html="element.text" />
        </div>
      </div>

      <!-- <div
        class="editor-template-content__cropper"
        v-if="showCropper"
        :style="croppieStyle"
      >
        <vue-croppie
          class="editor-template-content__croppie"
          ref="croppieRef"
          :enableOrientation="true"
          :enableResize="false"
          :enforceBoundary="false"
          :mouseWheelZoom="false"
          :boundary="boundary"
          :viewport="viewport"
        >
        </vue-croppie>
        <div class="editor-template-content__croppie-card">
          <input
            class="editor-template-content__range"
            type="range"
            v-model="range"
            min="0.0000"
            max="1.5000"
            step="0.001"
          />
          <div class="editor-template-content__croppie-card-divider"></div>
          <div class="editor-template-content__croppie-card-buttons">
            <Button
              class="editor-template-content__croppie-card-button--cancel"
              theme="secondary"
              @click="onCloseCrop"
            >
              {{ $t("pages.editor.croppie.cancel") }}
            </Button>
            <Button
              class="editor-template-content__croppie-card-button--confirm"
              @click="onCrop"
            >
              {{ $t("pages.editor.croppie.confirm") }}
            </Button>
          </div>
        </div>

        <div class="editor-template-content__backdrop"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import EditorTemplateElementBar from "./EditorTemplateElementBar.vue";
//import Button from "@/components/Button.vue";
import Stack from "@/components/Stack.vue";
import { mapActions, mapGetters } from "vuex";
import html2canvas from "html2canvas";
//import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Stack,
    EditorTemplateElementBar
    //Button
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    template: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    isPaused: true,
    showCanvas: false
    // elementCSS: {
    //   width: "0px",
    //   height: "0px"
    // }
    // cropElementIndex: null,
    // showCropper: false,
    // range: 1
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentBrand: "getParentBrand",
      generalAssets: "getGeneralAssets"
    }),
    ...mapGetters("templates", {
      tempTemplate: "getTempTemplate"
    }),
    videoPlaceholder() {
      return require("../../assets/img/video-placeholder.png");
    },
    imagePlaceholder() {
      return require("../../assets/img/image-placeholder.png");
    },
    logoPlaceholder() {
      return require("../../assets/icons/logo-placeholder.svg");
    },
    brand() {
      if (this.parentBrand) {
        return this.parentBrand;
      } else {
        return this.team.brand ? this.team.brand : {};
      }
    },
    textStyles() {
      return this.brand?.textStyles;
    },
    previewStyle() {
      if (this.tempTemplate) {
        let css = {
          ...this.tempTemplate.css[0],
          ...this.tempTemplate.css[this.orientation]
        };
        const windowWidth = window.innerWidth;
        if (windowWidth > 1400 && windowWidth < 1549) {
          css.transform = "scale(0.4)";
        }

        return css;
      }

      return {};
    }
    // croppieStyle() {
    //   return {
    //     position: "absolute",
    //     top: this.elementCSS.top,
    //     left: this.elementCSS.left,
    //     right: this.elementCSS.right,
    //     bottom: this.elementCSS.bottom
    //     // width: this.elementCSS.width,
    //     // height: this.elementCSS.height
    //   };
    // },
    // boundary() {
    //   return {
    //     width: this.elementCSS.width,
    //     height: this.elementCSS.height
    //   };
    // },
    // viewport() {
    //   return {
    //     width: this.elementCSS.width,
    //     height: this.elementCSS.height,
    //     type: "square"
    //   };
    // }
  },
  watch: {
    // range() {
    //   this.$refs.croppieRef.setZoom(this.range);
    // },
    template() {
      if (!this.generalAssets && this.template && this.slide.elements) {
        this.fetchGeneralAssets().then(() => {
          this.setSlideElements();
          this.setFonts();
          this.setColors();
        });
      }
    },
    slide: {
      handler() {
        this.setSlideElements();
      },
      deep: true
    }
  },
  mounted() {
    if (!this.generalAssets) {
      this.fetchGeneralAssets().then(() => {
        this.setSlideElements();
        this.setFonts();
        this.setColors();
      });
    } else {
      this.setSlideElements();
      this.setFonts();
      this.setColors();
    }
  },
  methods: {
    ...mapActions("teams", ["fetchGeneralAssets"]),
    // onShowCrop(index) {
    //   this.cropElementIndex = index;
    //   let element = this.template.elements[index];
    //   this.elementCSS = element.css[this.orientation];
    //   this.showCropper = true;
    //   this.$nextTick(() => {
    //     console.log(this.$refs.croppieRef);
    //     this.$refs.croppieRef.bind({
    //       url: element.image.src
    //     });
    //     this.$refs.croppieRef.setZoom(this.range);
    //   });
    // },
    // onCrop() {
    //   let options = {
    //     type: "base64",
    //     size: {
    //       width: this.elementCSS.width.replace("px", ""),
    //       height: this.elementCSS.height.replace("px", "")
    //     }
    //   };
    //   console.log("options", options);
    //   console.log("croppieRef", this.$refs.croppieRef);
    //   this.$refs.croppieRef.result(options, output => {
    //     console.log("output", output);
    //     this.template.elements[this.cropElementIndex].image.src = output;
    //     this.onCloseCrop();
    //   });
    // },
    // onCloseCrop() {
    //   this.showCropper = false;
    //   this.cropElementIndex = null;
    //   this.$refs.croppieRef.value = null;
    // },
    getImagePlaceHolderStyle(element) {
      return `background-image: url(${this.imagePlaceholder}); background-size: ${element.classes.imageStyles}`;
    },
    getImageStyle(element) {
      return `background-image: url(${element.image.src}); background-size: ${element.classes.imageStyles}`;
    },
    getLogoStyle(element) {
      if (element.classes.logoType === "bright") {
        if (this.brand && this.brand.logo) {
          return `background-image: url(${this.brand.logo});`;
        } else if (this.brand && this.brand.logoDark) {
          return `background-image: url(${this.brand.logoDark});`;
        } else {
          return `background-image: url(${this.logoPlaceholder});`;
        }
      } else if (element.classes.logoType === "dark") {
        if (this.brand && this.brand.logoDark) {
          return `background-image: url(${this.brand.logoDark});`;
        } else if (this.brand && this.brand.logo) {
          return `background-image: url(${this.brand.logo});`;
        } else {
          return `background-image: url(${this.logoPlaceholder});`;
        }
      }
    },
    checkIfPlaceholder(element) {
      if (typeof element.video.src === "string") {
        return element.video.src.includes("img/video-placeholder");
      }
    },
    onDurationChanged(duration) {
      this.slide.duration = duration;
    },
    onPlay() {
      this.$emit("preview-status-change", true);

      let videos = document.getElementsByTagName("video");
      for (let video of videos) {
        video.play();
      }

      this.isPaused = false;
      setTimeout(() => {
        this.resetPlayer();
      }, this.slide.duration * 1000);
    },
    resetPlayer() {
      this.isPaused = true;
      let videos = document.getElementsByTagName("video");
      for (let video of videos) {
        video.pause();
        video.currentTime = 0;
      }
      this.$emit("preview-status-change", false);
    },
    getTextStyle(element) {
      let styles = {
        fontSize: this.textStyles[element.classes.textStyles]?.fontSize
          ? this.textStyles[element.classes.textStyles].fontSize + "px"
          : undefined,
        lineHeight: this.textStyles[element.classes.textStyles]?.lineHeight
          ? this.textStyles[element.classes.textStyles].lineHeight + "px"
          : undefined,
        textTransform: this.textStyles[element.classes.textStyles]
          ?.textTransform
          ? this.textStyles[element.classes.textStyles].textTransform
          : undefined,
        letterSpacing: this.textStyles[element.classes.textStyles]
          ?.letterSpacing
          ? this.textStyles[element.classes.textStyles].letterSpacing
          : undefined
      };

      return {
        ...element.css[0],
        ...element.css[this.orientation],
        fontSize: element.css[this.orientation]?.fontSize
          ? element.css[this.orientation].fontSize
          : styles.fontSize,
        lineHeight: element.css[this.orientation]?.lineHeight
          ? element.css[this.orientation].lineHeight
          : styles.lineHeight,
        textTransform: element.css[this.orientation]?.textTransform
          ? element.css[this.orientation].textTransform
          : styles.textTransform,
        letterSpacing: element.css[this.orientation]?.letterSpacing
          ? element.css[this.orientation].letterSpacing
          : styles.letterSpacing
      };
    },
    getStyle(element) {
      return {
        ...element.css[0],
        ...element.css[this.orientation]
      };
    },
    setSlideElements() {
      if (
        this.$route.params.id &&
        this.$route.params.templateId &&
        this.template
      ) {
        if (this.template.elements && this.template.elements.length > 0) {
          this.template.elements = this.template.elements.map(element => {
            if (this.slide?.template?.elements) {
              let slideElement = this.slide.template.elements.filter(
                e => e.id === element.id
              )[0];
              return { ...element, ...slideElement };
            }
          });
        }
      }
    },
    // drawCanvas() {
    //   Object.assign(
    //     document.getElementsByClassName("editor-template-content__artboard")[0]
    //       .style,
    //     {
    //       ...this.tempTemplate.css[0],
    //       ...this.tempTemplate.css[this.orientation]
    //     }
    //   );
    //   this.drawChildren(
    //     this.template.elements,
    //     ".editor-template-content__artboard"
    //   );
    // },
    // drawChildren(elements, parent) {
    //   for (var key in elements) {
    //     var element = elements[key];
    //     var text = "";
    //     if (element.text) {
    //       text = "<span>" + element.text + "</span>";
    //     }

    //     var classes = "";
    //     for (var classKey in element.classes) {
    //       var className = element.classes[classKey];
    //       if (className != null) {
    //         classes += className;
    //       }
    //     }
    //     var image = "";
    //     if (element.image != undefined) {
    //       if (element.image.src.includes("img/image-placeholder")) {
    //         image = "<img src='" + this.imagePlaceholder + "'></img>";
    //       } else {
    //         image = "<img src='" + element.image.src + "'>";
    //       }
    //     }

    //     if (element.video != undefined) {
    //       if (this.checkIfPlaceholder(element)) {
    //         image =
    //           "<video crossorigin='anonymous' poster='" +
    //           this.videoPlaceholder +
    //           "' src='" +
    //           this.videoPlaceholder +
    //           "'></video>";
    //       } else {
    //         console.log("nope");
    //         image =
    //           "<video crossorigin='anonymous' src='" +
    //           element.video.src +
    //           "'></video>";
    //       }
    //     }

    //     document
    //       .querySelector(parent)
    //       .insertAdjacentHTML(
    //         "beforeend",
    //         "<" +
    //           element.tag +
    //           " data-class='" +
    //           classes +
    //           "' data-index=" +
    //           key +
    //           " data-id=" +
    //           element.id +
    //           " data-type='" +
    //           element.type +
    //           "'>" +
    //           text +
    //           image +
    //           "</" +
    //           element.type +
    //           ">"
    //       );
    //     //document.querySelector(parent).querySelectorAll("*[data-id=" + element.id + "]")[0].css(element.css)
    //     if (element.type === "text") {
    //       console.log(element.classes);
    //       Object.assign(
    //         document
    //           .querySelector(parent)
    //           .querySelectorAll("*[data-id='" + element.id + "']")[0].style,
    //         this.getTextStyle(element)
    //       );
    //       document
    //         .querySelector(parent)
    //         .querySelectorAll("*[data-id='" + element.id + "']")[0]
    //         .classList.add(element.classes.textStyles);
    //     } else {
    //       Object.assign(
    //         document
    //           .querySelector(parent)
    //           .querySelectorAll("*[data-id='" + element.id + "']")[0].style,
    //         { ...element.css[0], ...element.css[this.orientation] }
    //       );
    //     }

    //     this.drawChildren(
    //       element.children,
    //       parent + " *[data-id='" + element.id + "']"
    //     );
    //   }
    // },
    setFonts() {
      let textStyles = Object.values(this.textStyles);
      let newStyle = document.createElement("style");
      let fonts = {};

      if (this.brand && this.brand.fonts) {
        fonts = {
          ...this.generalAssets.brand.fonts,
          ...this.brand.fonts
        };
      } else {
        fonts = {
          ...this.generalAssets.brand.fonts
        };
      }

      //let url = null;
      textStyles.forEach(font => {
        newStyle.appendChild(
          document.createTextNode(
            "\
              @font-face {\
                  font-family: " +
              font.fontFamily +
              ";\
                  src: url('" +
              fonts[font.fontId].url +
              "')\
                  format('woff');\
              }\
              "
          )
        );
      });

      document.head.appendChild(newStyle);
    },
    setColors() {
      if (this.brand && this.brand.colors) {
        let brandColors = Object.entries(this.brand.colors).map(
          ([id, color]) => {
            return { id, color: color.color };
          }
        );
        const excludedColors = ["primary", "textLight", "textDark"];
        let colors = brandColors.filter(color => {
          return !excludedColors.includes(color.id);
        });

        let newBrandStyle = document.createElement("style");

        newBrandStyle.appendChild(
          document.createTextNode(
            `:root {
            --main-primary: ${this.brand.colors.primary.color};
            --main-text-light: ${this.brand.colors.textLight.color};
            --main-text-dark: ${this.brand.colors.textDark.color};
            ${colors
              .map(
                (color, index) =>
                  `--main-secondary-${index + 1}: ${color.color};`
              )
              .join("\n")}
          }`
          )
        );

        document.head.appendChild(newBrandStyle);
      }
    },
    createThumbnail() {
      return new Promise(resolve => {
        this.showCanvas = true;

        this.$nextTick(() => {
          // Get the element to capture
          const preview = this.$refs.preview;

          let canvas = this.$refs.canvas; // declare a canvas element in your html
          let ctx = canvas.getContext("2d");
          let videos = document.getElementsByClassName("video-container");
          let w, h;
          for (let i = 0, len = videos.length; i < len; i++) {
            const v = videos[i].getElementsByTagName("video")[0];
            if (!v.src) continue; // no video here
            try {
              v.setAttribute("crossorigin", "anonymous");
              w = v.videoWidth;
              h = v.videoHeight;
              canvas.width = w;
              canvas.height = h;
              ctx.fillRect(0, 0, w, h);
              ctx.drawImage(v, 0, 0, w, h);
              v.style.backgroundImage = v.src.includes("img/video-placeholder")
                ? `url('${v.src}')`
                : `url(${canvas.toDataURL()})`; // here is the magic
              v.style.backgroundSize = v.className;
              v.style.backgroundRepeat = "no-repeat";
              v.style.backgroundPosition = "center";
              // v.style.objectFit = "contain";
              ctx.clearRect(0, 0, w, h); // clean the canvas
            } catch (e) {
              console.log("error", e);
              continue;
            }
          }

          html2canvas(preview, {
            logging: true,
            letterRendering: 1,
            allowTaint: false,
            useCORS: true
          }).then(canvas => {
            this.showCanvas = false;
            const data = canvas.toDataURL();
            this.$emit("thumbnail-created", data);
            // let img = new Image();

            // img.src = data;
            // document.body.appendChild(img);
            resolve(data);
          });
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/css/animations.css";

.preview-btn {
  position: absolute;
  top: 50px;
  left: 286px;
}

// .create-preview-btn {
//   position: absolute;
//   top: 80px;
//   left: 286px;
//   z-index: 1000;
// }

.orientation-2:before {
  content: "";
  width: 25%;
  left: 25%;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  border-left: 1px solid rgba(255, 0, 0, 0.25);
  pointer-events: none;
  z-index: 100;
}
.orientation-2:after {
  content: "";
  width: 25%;
  right: 25%;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  border-left: 1px solid rgba(255, 0, 0, 0.25);
  border-right: 1px solid rgba(255, 0, 0, 0.25);
  pointer-events: none;
}

.editor-template-content__preview * {
  margin: auto;
}

.editor-template-content__preview span {
  margin: unset;
}
.editor-template-content {
  // height: calc(100vh - 53px);
  // width: calc(100vw - 560px) !important;
  // margin: 0 auto;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__canvas {
    position: fixed;
  }

  &__preview-wrapper {
    //display: none;
    position: relative;
    transform-origin: top center;
  }

  &__preview {
    // position: absolute !important;
    top: 64px;
    overflow: hidden !important;

    position: absolute !important;
    // transform: scale(1) !important;
  }

  // &__croppie {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: flex-start;
  //   flex-direction: column;
  //   margin: 0;
  // }

  // .cr-boundary {
  //   @apply m-0 border border-ls-gray-300;
  // }

  // .cr-overlay {
  //   width: 100% !important;
  //   height: 100% !important;
  //   top: 0 !important;
  //   left: 0 !important;
  // }

  // .cr-slider-wrap {
  //   display: none;
  // }

  // &__croppie-card {
  //   @apply p-6 mt-6 shadow-ls-shadow bg-white rounded;

  //   &-divider {
  //     @apply my-4 bg-gray-200 w-full;
  //     height: 1px;
  //   }

  //   &-buttons {
  //     @apply flex;
  //   }

  //   &-button--cancel {
  //     @apply mr-2;
  //   }
  // }

  // &__range {
  //   width: 100%;
  // }

  &__text-element {
    //display: inline-block !important;
    white-space: pre-line;
    position: relative;
  }

  &__shape {
    svg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
  .image-container {
    &__remove-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);

      img {
        width: 96px !important;
        height: 96px !important;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
      }
    }
    &__image,
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &__logo {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .video-container {
    video {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (min-width: 1550px) {
  .editor-template-content__preview {
    //transform: scale(0.5) !important;
    transform-origin: top center;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1549px) {
  .editor-template-content__preview {
    //transform: scale(0.4) !important;
    transform-origin: top center;
  }
}
</style>
