<template>
  <div class="editor-content">
    <div class="editor-content__wrapper" v-if="url && isPicture">
      <div v-if="orientation === '0'">
        <div
          class="editor-content__preview"
          v-if="url.image && url.image.landscape"
        >
          <img
            class="editor-content__preview-image--landscape"
            :src="url.image.landscape"
          />
        </div>
        <div
          class="editor-content__empty editor-content__empty--landscape"
          v-if="url.image && !url.image.landscape"
        ></div>
      </div>

      <div v-if="orientation === '1'">
        <div
          class="editor-content__preview"
          v-if="url.image && url.image.portrait"
        >
          <img
            class="editor-content__preview-image--portrait"
            :src="url.image.portrait"
          />
        </div>
        <div
          class="editor-content__empty editor-content__empty--portrait"
          v-if="url.image && !url.image.portrait"
        ></div>
      </div>

      <div class="editor-content__bottom">
        <PictureUpload
          @on-image-set="setImage"
          :orientation="orientation"
          v-if="isPicture"
        />
      </div>
    </div>

    <div class="editor-content__wrapper" v-if="url && !isPicture">
      <div v-if="orientation === '0'">
        <div
          class="editor-content__preview"
          v-if="url.video && url.video.landscape"
        >
          <video
            class="editor-content__preview-image--landscape"
            :src="url.video.landscape"
            muted
          />
        </div>
        <div
          class="editor-content__empty editor-content__empty--landscape"
          v-if="url.video && !url.video.landscape"
        ></div>
      </div>

      <div v-if="orientation === '1'">
        <div
          class="editor-content__preview"
          v-if="url.video && url.video.portrait"
        >
          <video
            class="editor-content__preview-image--portrait"
            :src="url.video.portrait"
            muted
          />
        </div>
        <div
          class="editor-content__empty editor-content__empty--portrait"
          v-if="url.video && !url.video.portrait"
        ></div>
      </div>

      <div class="editor-content__bottom" v-if="newSlide">
        <label
          for="editor-content__video-upload-btn"
          class="editor-content__video-upload-btn"
        >
          {{ $t("pages.editor.uploadVideo") }}
        </label>
        <input
          id="editor-content__video-upload-btn"
          type="file"
          ref="videoFile"
          @change="setVideo"
          accept=".mov,.mp4"
        />
        <div class="editor-content__feedback" v-if="feedback">
          {{ feedback }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PictureUpload from "./PictureUpload";

export default {
  components: {
    PictureUpload
  },
  props: {
    url: {
      type: Object,
      default: () => {}
    },
    slide: {
      type: Object,
      default: () => {}
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    feedback: ""
  }),
  computed: {
    newSlide() {
      return !this.$route.params.id;
    },
    isPicture() {
      return this.$route.params.file === "image" ? true : false;
    }
  },
  methods: {
    openPictureModal() {
      this.$emit("open-picture-modal");
    },
    setImage(image) {
      this.$emit("on-image-set", {
        image: image
      });
    },
    getVideoDuration(file) {
      return new Promise((resolve, reject) => {
        try {
          let video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = function() {
            resolve(Math.floor(video.duration));
          };

          video.onerror = function() {
            reject("Invalid video. Please select a video file.");
          };

          video.src = window.URL.createObjectURL(file);
        } catch (e) {
          reject(e);
        }
      });
    },
    async setVideo(e) {
      const maxFileSize = 1024 * 1024 * 1024; // 1 GB
      const allowedExtensions = ["mov", "mp4"];
      let file = e.target.files[0];
      const extension = file.name.split(".").pop();
      const size = file.size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.feedback = "";

        if (size < maxFileSize) {
          this.feedback = "";
          let video = {};
          video.src = e.target.files[0];
          video.name = e.target.files[0].name.replace(/\..+$/, "");

          let payload = {};
          payload.duration = await this.getVideoDuration(e.target.files[0]);
          payload.video = video;
          payload.url = URL.createObjectURL(video.src);

          this.$emit("on-video-set", payload);

          this.$refs.videoFile.value = null;
        } else {
          this.feedback = this.$t("pages.editor.videoMaxFileSize");
        }
      } else {
        this.feedback = this.$t("pages.editor.videoFileType");
      }
    },
    onPlay() {
      this.$emit("preview-status-change", true);

      let videos = document.getElementsByTagName("video");
      for (let video of videos) {
        video.play();
      }

      setTimeout(() => {
        this.resetPlayer();
      }, this.slide.duration * 1000);
    },
    resetPlayer() {
      let videos = document.getElementsByTagName("video");
      for (let video of videos) {
        video.pause();
        video.currentTime = 0;
      }
      this.$emit("preview-status-change", false);
    }
  }
};
</script>

<style lang="scss">
.editor-content {
  padding-top: 56px;
  padding-right: 280px;

  &__wrapper {
    padding: 1rem 0 2rem;
    margin: 0 auto;
    width: fit-content;
  }

  &__preview {
    @apply mt-4 mb-5 border border-ls-gray-300 bg-white;
  }

  &__feedback {
    @apply text-ls-red mt-2 text-xs font-semibold;
  }

  &__preview-image--landscape,
  &__preview-image--grid {
    height: auto;
    width: calc(100vw - 580px);
    aspect-ratio: 16 / 9;
  }

  &__preview-image--portrait {
    width: auto;
    height: calc(100vh - 200px);
    aspect-ratio: 9 / 16;
  }

  &__empty {
    @apply mt-4 mb-5 border border-ls-gray-300 bg-white;

    &--landscape {
      height: auto;
      width: calc(100vw - 580px);
      aspect-ratio: 16 / 9;
    }

    &--portrait {
      width: auto;
      height: calc(100vh - 200px);
      aspect-ratio: 9 / 16;
    }
  }

  &__bottom {
    @apply mt-5;
  }

  &__dropdown {
    @apply text-xs font-semibold;

    .dropdown__selected-wrapper {
      border: none;
    }

    &-wrapper {
      @apply flex items-center;
    }

    &-icon-wrapper {
      @apply bg-ls-gray-300 p-1 rounded-full;
    }
  }

  &__video-upload-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  input[type="file"] {
    display: none;
  }

  &__upload-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }
}
</style>
